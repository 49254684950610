




































import sink from '@/sink';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';
import PatientStatusUpdateDialogVue from './PatientStatusUpdateDialog.vue';

export default defineComponent({
  setup() {
    return {
      headerInfo: sink.select('patients.detail.header'),
      updatePatientStatus: () =>
        DialogInstance.open(PatientStatusUpdateDialogVue),
    };
  },
});
