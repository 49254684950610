







import sink from '@/sink';
import { defineComponent, onMounted, onUnmounted } from '@vue/composition-api';
import { PatientDetailMounted, PatientDetailUnmounted } from './+state/events';
import PatientsDetailHeader from './header/PatientsDetailHeader.vue';

export default defineComponent({
  components: { PatientsDetailHeader },
  setup() {
    onMounted(sink.lazyBroadcast(PatientDetailMounted()));
    onUnmounted(sink.lazyBroadcast(PatientDetailUnmounted()));
  },
});
